<template>
    <div>
        <template v-if="options.length > 0">
            <p v-for="option of options">
                <label :for="config.fieldPath + '-' + option.id">
                    <input :id="config.fieldPath + '-' + option.id"
                           :checked="isChecked(option)"
                           :name="config.fieldPath + '[]'"
                           :value="option.id"
                           type="checkbox"
                           @change="isChecked(option) ? deselect(option) : select(option)">
                    {{ option[config.titleField] }}
                </label>
            </p>
        </template>
        <template v-else>
            <p>
                {{ config.noneFound }}
            </p>
        </template>
    </div>
</template>

<script>
    import axios from 'axios';
    import DatoCmsPlugin from 'datocms-plugins-sdk';

    export default {
        name: 'ProcessesSelect',
        data() {
            return {
                options: [],
                value: [],
                config: {
                    // Global
                    graphqlUrl: 'https://graphql.datocms.com',
                    graphqlToken: '',
                    // Instance
                    titleField: 'name',
                    fieldPath: 'processes',
                    relatedField: {
                        local: 'category',
                        reference: 'categories',
                    },
                },
                unsubscribeCategories: () => null,
            };
        },
        watch: {
            value(newValue, oldValue) {
                this.plugin.setFieldValue(this.config.fieldPath, newValue);
            },
        },
        methods: {
            select(option) {
                this.value.push(option.id);
            },
            deselect(option) {
                const index = this.value.indexOf(option.id);

                if (index < 0) {
                    return;
                }

                this.value.splice(index, 1);
            },
            isChecked(option) {
                return this.value.includes(option.id);
            },
            async onRelatedValuesChanged(relatedValues) {
                let optionName = this.config.fieldPath ?? 'Processes';
                optionName = optionName.charAt(0).toUpperCase() + optionName.substr(1);
                const relatedField = this.config.relatedField.foreign ?? 'category';
                let query = 'query ($otherIds: [ItemId]) {' + '\n' +
                            'all' + optionName + '(filter: {' + relatedField + ': {in: $otherIds}}) {' + '\n'
                            + 'id' + '\n'
                            + 'name' + '\n'
                            + '}' + '\n'
                            + '}';
                const response = await axios.post(
                    this.config.graphqlUrl,
                    {
                        query,
                        variables: {
                            otherIds: relatedValues,
                        },
                    }, {
                        headers: {
                            'Authorization': `Bearer ${this.config.graphqlToken}`,
                        },
                    },
                );

                if (response.data == null || response.data.data == null || response.data.data[`all${optionName}`] == null) {
                    this.options = [];
                    return;
                }

                this.options = response.data.data[`all${optionName}`];
            },
        },
        mounted() {
            const self = this;
            DatoCmsPlugin.init(async plugin => {
                this.plugin = plugin;

                plugin.startAutoResizer();

                this.config.graphqlUrl = plugin.parameters.global.graphqlUrl;
                this.config.graphqlToken = plugin.parameters.global.graphqlToken;
                this.config.titleField = plugin.parameters.global.titleField ?? this.config.titleField;
                this.config.noneFound = plugin.parameters.instance.noneFound ?? 'Geen resultaten gevonden';

                this.value = plugin.getFieldValue(plugin.fieldPath) ?? [];
                this.config.fieldPath = plugin.fieldPath ?? this.config.fieldPath;
                this.config.relatedField = {
                    local: plugin.parameters.instance.localRelatedField ?? 'categories',
                    foreign: plugin.parameters.instance.foreignRelatedField ?? 'category',
                };

                this.unsubscribeCategories = plugin.addFieldChangeListener(this.config.relatedField.local, (selected) => {
                    self.onRelatedValuesChanged(selected);
                });

                await this.onRelatedValuesChanged(plugin.getFieldValue(this.config.relatedField.local));
            });
        },
        beforeDestroy() {
            this.unsubscribeCategories();
        },
    };
</script>

<style lang="scss">
    p, label {
        cursor                : default;
        user-select           : text;
        font-family           : colfax-web, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size             : 15px;
        font-weight           : 400;
        line-height           : 22.5px;
        letter-spacing        : normal;
        color                 : rgb(132, 132, 132);
        text-rendering        : optimizelegibility;
        text-size-adjust      : 100%;
        -webkit-box-direction : normal;
    }

    p {
        margin : 0 0 8px 0;
        color  : black;
    }
</style>
