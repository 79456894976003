import ProcessesSelect from '@/views/ProcessesSelect';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/processes-select',
        name: 'Processes Select',
        component: ProcessesSelect,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
